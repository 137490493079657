import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ReactComponent as Note } from "../../../../assets/note.svg";
import { ReactComponent as DfMids } from "../../../../assets/df mids.svg";
import { ReactComponent as Pm } from "../../../../assets/pm.svg";
import { ReactComponent as Trader } from "../../../../assets/trader.svg";
import { ReactComponent as Info } from "../../../../assets/info.svg";
import { ReactComponent as Email } from "../../../../assets/email.svg";
import { ReactComponent as Launch } from "../../../../assets/launch.svg";
import { ReactComponent as Client } from "../../../../assets/client.svg";
import { ReactComponent as Mm } from "../../../../assets/market_maker.svg";
import { ReactComponent as Split } from "../../../../assets/split.svg";
import Autocomplete from "@mui/material/Autocomplete";
import EmailModal from "../../../fx/modals/emailModal/EmailModal";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import * as rfqPostTradeSlice from "../../../../store/rfq/rfqPostTradeSlice";
import * as emailActions from "../../../../store/email_confo/emailConfoSlice";
import "./RfqBookingMode.css";
import axios from "axios";
import { END_POINT } from "../../../../utils";
import RfqSplitModal from "../../../fx/modals/rfqSplitModal/RfqSplitModal";
import RfqBookModal from "../../../fx/modals/rfqBookModal/RfqBookModal";
import * as actionSnackBar from "../../../../store/snackbar/action";
import {
  useStyles,
  StyledTextField,
} from "../../../../Styles/rfqPostTradeStyles";
import { clientVerifier } from "./utils";
import { RfqEConfoModal } from "../E_ConfoModal/RfqEConfoModal";
import { ReactComponent as CheckedCircleIcon } from "../../../../assets/checked_circle.svg"; // Import your SVG

const RfqBookingMode = ({ header, rfq_object, setrfq_object }) => {
  const [openSplitDialog, setOpenSplitDialog] = useState(false);
  const [openBookDialog, setOpenBookDialog] = useState(false);
  const [openEConfoDialog, setOpenEConfoDialog] = useState(false);
  //const [marketMakers, setMarketMakers] = useState(["OG-RBS", "MAKOR-RBS"]);
  // ! replace when Sourabh ask
  const [marketMakers, setMarketMakers] = useState([
    "OG-RBS",
    "MAKOR-RBS",
    "MAKOR-HSBC",
  ]);
  const [bookTradeResponse, setBookTradeResponse] = useState("");
  const [confirmedResponse, setConfirmedResponse] = useState("");
  const [notionalSplitCopy, setNotionalSplitCopy] = useState();
  const [booked, setBooked] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [bookedValues, setBookedValues] = useState([]);
  const { rfqId, rfq, rfqBookingModeUpdatingRows } = useSelector(
    (state) => state.rfqSlice,
  );
  const [pbValue, setPbValue] = useState("");
  const [rfqStatus, setRfqStatus] = useState("");
  const [splitted, setSplitted] = useState(false);
  const token = sessionStorage.getItem("token");
  const classes = useStyles();
  const [currentMarketMakerDetails, setCurrentMarketMakerDetails] = useState(
    [],
  );
  const [currentClientsDetails, setCurrentClientsDetails] = useState([]);
  const [currentPmDetails, setCurrentPmDetails] = useState([]);
  const [currentTraderDetails, setCurrentTraderDetails] = useState([]);
  const [clientId, setClientId] = useState(0);
  const [marketMakerId, setMarketMakerId] = useState(0);

  const [isClientMarketMaker, setIsClientMarketMaker] = useState(false);
  const [isMarketMakerClient, setIsMarketMakerClient] = useState(false);

  //EMAIL STATES
  const [openConfoDialog, setOpenConfoDialog] = useState(false);
  const [confoModalType, setConfModalType] = useState(false);
  const rfqIdStorage = sessionStorage.getItem("rfqId");
  const dispatch = useDispatch();

  const {
    postTradeSplitArrPreValues,
    thereIsConfo,
    splitManner,
    makorHSBCClient,
    isSplited,
    makorHSBCMarketMakers,
    allMakorHSBCClientAndMarketMakers,
    makorMarketMakers,
    makorClients,
    oscarGrussMarketMakers,
    oscarGrussClients,
    currentClient,
    currentMarketMaker,
    marketMaker,
    confoInitial,
    marketMakerNeedsConfo,
    currentPb,
    trader,
    dfMids,
    traderNote,
    postTradeSplitArrValues,
    singleConfo,
    rfqObject,
    sd,
    pba,
    startSendEmailStatus,
  } = useSelector((state) => state.rfqPostTradeSlice);
  const openSplitDialogAction = () => {
    if (currentMarketMaker !== "" || booked) {
      setOpenSplitDialog(!openSplitDialog);
    } else if (currentMarketMaker === "") {
      dispatch(
        actionSnackBar.setSnackBar("error", "Please select market maker", 3000),
      );
    }
  };
  const DEAL_TYPE = rfqObject?.rfq_table_data?.find(
    (field) => field[0] === "DEAL_TYPE",
  )[1][0];
  //test if rfq is booked
  useEffect(() => {
    const getBookedStats = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.TRADES +
            `/${rfqIdStorage}`,
          {
            headers: { Authorization: token },
          },
        );
        if (res.data.length > 0) {
          setBookedValues(res.data[0]);
          if (res.data[0].status === "Confirmed") {
            setConfirmed(true);
          }
          setBooked(true);
        }
      } catch (error) {}
    };
    if (sessionStorage.getItem("rfqId") !== null) {
      getBookedStats();
    }
  }, [rfqIdStorage]);

  const handleSplitDialogClose = () => {
    setOpenSplitDialog(!openSplitDialog);
  };

  const openBookDialogAction = () => {
    setOpenBookDialog(!openBookDialog);
  };

  const handleBookDialogClose = () => {
    setOpenBookDialog(!openBookDialog);
  };
  const handleEConfoDialogClose = () => {
    setOpenEConfoDialog(!openEConfoDialog);
  };

  const openConfoDialogAction = async (type, emailGenerateData) => {
    dispatch(emailActions.setEmailSavedData("initialize", "none"));
    if (currentMarketMaker === "") {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Please select PB Market Maker",
          3000,
        ),
      );
    } else if (
      (currentClient.length === 0 || currentClient[0] === null) &&
      postTradeSplitArrValues.length === 0
    ) {
      dispatch(
        actionSnackBar.setSnackBar("error", "Please select Client", 3000),
      );
    } else if (marketMaker === "" || marketMaker === null) {
      dispatch(
        actionSnackBar.setSnackBar("error", "Please select Market Maker", 3000),
      );
    } else if (
      (sd === true || currentMarketMaker === "OG-RBS" || pba === true) &&
      dfMids === ""
    ) {
      dispatch(actionSnackBar.setSnackBar("error", "Please set DfMids", 3000));
    } else if (
      currentMarketMaker !== "" &&
      (currentClient.length !== 0 || currentClient[0] !== null) &&
      (marketMaker !== "" || marketMaker !== null)
    ) {
      let allocation_list;
      if (postTradeSplitArrPreValues.length > 0) {
        const rfqFromPreValues = postTradeSplitArrPreValues.filter(
          (rfq) => rfq.rfq_id === rfqIdStorage,
        );
        if (rfqFromPreValues[0].split_manner === "percentage") {
          allocation_list = rfqFromPreValues[0].split_values.map((key) => {
            return {
              id: key.id,
              client_selection: key.postTradeClientSplitId,
              pm_selection: key.postTradePmSplitId,
              percentange_allocation: +key.postTradeSplitOption / 100,
            };
          });
        } else if (rfqFromPreValues[0].split_manner === "notional") {
          if (DEAL_TYPE !== "SWAP") {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "OPTION" }, { 0: "HEDGE" }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("Leg")) {
                  let legNum = key.match(/\d+/g);
                  obj.notional_allocation[0] = {
                    ...obj.notional_allocation[0],
                    [legNum]: value,
                  };
                }
                if (key.includes("Delta")) {
                  let deltaNum = key.match(/\d+/g);
                  obj.notional_allocation[1] = {
                    ...obj.notional_allocation[1],
                    [deltaNum]: value,
                  };
                }
              }
              return obj;
            });
          } else {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "SWAP", 1: 0, 2: 0 }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("near")) {
                  obj.notional_allocation["1"] = value;
                }
                if (key.includes("far")) {
                  obj.notional_allocation["2"] = value;
                }
              }
              return obj;
            });
          }
        }
      } else {
        if (splitManner === "percentage") {
          allocation_list = postTradeSplitArrValues.map((key) => {
            return {
              client_selection: key.postTradeClientSplitId,
              pm_selection: key.postTradePmSplitId,
              percentange_allocation: +key.postTradeSplitOption / 100,
            };
          });
        } else if (splitManner === "notional") {
          if (DEAL_TYPE !== "SWAP") {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "OPTION" }, { 0: "HEDGE" }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("Leg")) {
                  let legNum = key.match(/\d+/g);
                  obj.notional_allocation[0] = {
                    ...obj.notional_allocation[0],
                    [legNum]: value,
                  };
                }
                if (key.includes("Delta")) {
                  let deltaNum = key.match(/\d+/g);
                  obj.notional_allocation[1] = {
                    ...obj.notional_allocation[1],
                    [deltaNum]: value,
                  };
                }
              }
              return obj;
            });
          } else {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "SWAP", 1: 0, 2: 0 }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("near")) {
                  obj.notional_allocation["1"] = value;
                }
                if (key.includes("far")) {
                  obj.notional_allocation["2"] = value;
                }
              }
              return obj;
            });
          }
        }
      }

      let confo_details = {};
      if (currentMarketMaker === "MAKOR-HSBC") {
        let curentClientMarketMaker = makorHSBCMarketMakers.some(
          (mm) => mm.MmName === currentClient[0],
        );
        let marketMakerIsClient = makorHSBCClient.some(
          (client) => client.MmName === marketMaker,
        );
        let newAllocationList;
        if (allocation_list?.length > 0) {
          newAllocationList = allocation_list?.map((alo) => {
            let curentClientMarketMaker = makorHSBCMarketMakers.some(
              (mm) => mm.MmName === alo.client_selection,
            );
            let marketMakerIsClient = makorHSBCClient.some(
              (client) => client.MmName === alo.client_selection,
            );
            alo = {
              ...alo,
              is_marketmaker_client: marketMakerIsClient,
              is_client_marketmaker: curentClientMarketMaker,
            };
            return alo;
          });
        }

        confo_details = {
          client_selection: currentClient[0],
          market_maker_selection: marketMaker,
          pm_selection: currentPb !== "" ? currentPb : "",
          trader_selection: trader !== "" ? trader : "",
          trader_note: traderNote,
          df_mids: dfMids,
          market_maker_needs_confo: true,
          confo_initial: confoInitial,
          pb: currentMarketMaker,
          split_allocation:
            allocation_list?.length > 0 ? newAllocationList : null,
          singleConfo: allocation_list?.length > 0 ? singleConfo : null,
          is_client_marketmaker: curentClientMarketMaker,
          is_marketmaker_client: marketMakerIsClient,
          pba: pba,
        };
        console.log(confo_details, "confo_details11111");
      } else {
        confo_details = {
          client_selection: currentClient[0],
          market_maker_selection: marketMaker,
          pm_selection: currentPb !== "" ? currentPb : "",
          trader_selection: trader !== "" ? trader : "",
          trader_note: traderNote,
          df_mids: dfMids,
          market_maker_needs_confo: true,
          confo_initial: confoInitial,
          pb: currentMarketMaker,
          split_allocation:
            allocation_list?.length > 0 ? allocation_list : null,
          singleConfo: allocation_list?.length > 0 ? singleConfo : null,
          pba: pba,
        };
      }
      if (currentMarketMaker === "MAKOR-RBS") {
        if (sd === true) {
          confo_details = { ...confo_details, SD: sd };
        }
      }

      const res = rfq.filter(
        (r) => Number(r.rfq_id) === Number(rfq_object.rfq_id),
      );

      let statusToUpdate =
        res[0]?.status !== undefined ? res[0]?.status : res.status;
      statusToUpdate =
        statusToUpdate === "Live"
          ? "Confirmed"
          : statusToUpdate === "Booked"
          ? "Confirmed & Booked"
          : statusToUpdate === "Confirmed & Booked"
          ? "Confirmed & Booked"
          : "Confirmed";

      rfq_object = {
        ...rfq_object,
        status: statusToUpdate,
        rfq_id: sessionStorage.getItem("rfqId"),
      };

      rfq_object.rfq_id = sessionStorage.getItem("rfqId");
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_PYTON}` + `book_trade_preclearance`,
          {
            confo_details: confo_details,
            opts_data: rfq_object.rfq_table_data,
          },
        );
        rfq_object = {
          ...rfq_object,
          confo_details: confo_details,
          allocation_details: response.data,
        };

        let data = [
          response,
          rfq_object,
          confo_details,
          rfq_object.rfq_table_data,
          rfqBookingModeUpdatingRows,
        ];

        setConfirmedResponse(data);
        setConfModalType(type);
        setOpenConfoDialog(!openConfoDialog);
      } catch (error) {}
    }
  };

  //*********************************************************** */
  const handleMmChange = (e, chosenPB) => {
    e.preventDefault();
    if (isSplited) {
      dispatch(
        actionSnackBar.setSnackBar(
          "success",
          `Split data have been reset`,
          5000,
        ),
      );
    }
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "currentMarketMaker",
        value: chosenPB,
      }),
    );

    if (
      postTradeSplitArrPreValues.filter((t) => t.rfq_id === rfq_object.rfq_id)
        .length !== 0 &&
      chosenPB !== currentMarketMaker
    ) {
      dispatch(rfqPostTradeSlice.resetPreSplitValues(rfq_object.rfq_id));
      const splitNumberObj = {
        value: 2,
        key: "postTradeSplitNumber",
      };
      setSplitted(false);
      dispatch(rfqPostTradeSlice.setIsSplitted(false));
      dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(splitNumberObj));
      dispatch(rfqPostTradeSlice.resetPostTradeStats());
      dispatch(rfqPostTradeSlice.setCurrentClient(""));
      dispatch(rfqPostTradeSlice.setCurrentPb(""));
      dispatch(rfqPostTradeSlice.setMarketMaker(""));
      dispatch(rfqPostTradeSlice.setTrader(""));
      dispatch(rfqPostTradeSlice.setMMNeedsConfo(false));
    }
    dispatch(rfqPostTradeSlice.setCurrentMarketMaker(chosenPB));

    if (chosenPB === "OG-RBS") {
      let arrangeOscarGrussClients = oscarGrussClients.map((key) => {
        return key.ogName;
      });
      let OscarGrussClients = [...new Set(arrangeOscarGrussClients)];
      setCurrentClientsDetails((prev) => OscarGrussClients);

      let arrangeOscarGrussMarketMakers = oscarGrussMarketMakers.map((key) => {
        return key.ogName;
      });
      let OscarGrussMarketMakers = [...new Set(arrangeOscarGrussMarketMakers)];
      setCurrentMarketMakerDetails((prev) => OscarGrussMarketMakers);
    }
    if (chosenPB === "MAKOR-RBS") {
      let arrangeMakorClients = makorClients.map((key) => {
        return key.MmName;
      });
      let MakorClients = [...new Set(arrangeMakorClients)];
      setCurrentClientsDetails((prev) => MakorClients);

      let arrangeMakorMarketMakers = makorMarketMakers?.map((key) => {
        return key.MmName;
      });
      let MakorMarketMakers = [...new Set(arrangeMakorMarketMakers)];
      let arrangeOscarGrussClients = oscarGrussClients.map((key) => {
        return key.ogName;
      });
      let OscarGrussClients = [...new Set(arrangeOscarGrussClients)];
      let concatArray = [...MakorMarketMakers, ...OscarGrussClients];

      if (sd === true) {
        setCurrentMarketMakerDetails((prev) => concatArray);
      } else {
        setCurrentMarketMakerDetails((prev) => MakorMarketMakers);
      }
    }
    if (chosenPB === "MAKOR-HSBC") {
      let makorHSBCClients = makorHSBCClient.map((key) => {
        return key.MmName;
      });
      let makorHSBCMarketMaker = makorHSBCMarketMakers.map((key) => {
        return key.MmName;
      });
      let allMakorHSBCCLientsAndMarketMakers = [
        ...new Set(makorHSBCClients),
        ...new Set(makorHSBCMarketMaker),
      ];
      setCurrentClientsDetails((prev) => allMakorHSBCCLientsAndMarketMakers);
      setCurrentMarketMakerDetails(
        (prev) => allMakorHSBCCLientsAndMarketMakers,
      );
    }

    if (chosenPB !== "MAKOR-RBS") {
      if (sd) {
        dispatch(rfqPostTradeSlice.setSD(false));
      }
    }
  };

  const handleClientChange = (value) => {
    if (isSplited) {
      dispatch(
        actionSnackBar.setSnackBar(
          "success",
          `Split data have been reset`,
          5000,
        ),
      );
    }
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "currentClient",
        value: value,
      }),
    );
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "singleConfo",
        value: false,
      }),
    );
    if (
      postTradeSplitArrPreValues.filter((t) => t.rfq_id === rfq_object.rfq_id)
        .length !== 0 &&
      value !== currentMarketMaker
    ) {
      setSplitted(false);
      dispatch(rfqPostTradeSlice.setIsSplitted(false));
      dispatch(rfqPostTradeSlice.resetPreSplitValues(rfq_object.rfq_id));
      dispatch(rfqPostTradeSlice.resetPostTradeStats());
      dispatch(rfqPostTradeSlice.setSingleConfo(false));
      dispatch(rfqPostTradeSlice.setCurrentPb(""));
    }
    dispatch(rfqPostTradeSlice.setCurrentClient(value));
    if (currentMarketMaker === "OG-RBS" || sd === true) {
      let findClientPms = oscarGrussClients.filter((key) => {
        if (key.ogName === value) {
          setClientId(key.clientId);
          return key.ogName;
        }
      });
      let pms = findClientPms.map((key) => {
        return key.ogPm;
      });
      if (pms.length > 0 && pms[0] !== null) {
        setCurrentPmDetails(pms);
      } else {
        setCurrentPmDetails(["No PMs found"]);
      }

      dispatch(rfqPostTradeSlice.setPBA(findClientPms[0]?.pba));
    }
    if (currentMarketMaker === "MAKOR-RBS" && sd === false) {
      let findClientPms = makorClients.filter((key) => {
        if (key.MmName === value) {
          setClientId(key.clientId);
          return key.MmName;
        }
      });
      let pms = findClientPms.map((key) => {
        return key.MmPm;
      });
      if (pms.length > 0 && pms[0] !== null) {
        setCurrentPmDetails(pms);
      } else {
        setCurrentPmDetails(["No PMs found"]);
      }
      dispatch(rfqPostTradeSlice.setPBA(findClientPms[0]?.pba));
      // setClientPba(findClientPms[0].pba);
    }
    if (currentMarketMaker === "MAKOR-HSBC") {
      let findClientPms = makorHSBCClient.filter((key) => {
        if (key.MmName === value) {
          setClientId(key.clientId);
          return key.MmName;
        }
      });
      let findMarketMakerPms = makorHSBCMarketMakers.filter((key) => {
        if (key.MmName === value) {
          setClientId(key.clientId);
          return key.MmName;
        }
      });
      let allPms = [...findClientPms, ...findMarketMakerPms];
      let pms = allPms.map((key) => {
        return key.MmPm;
      });
      if (pms.length > 0 && pms[0] !== null) {
        setCurrentPmDetails(pms);
      } else {
        setCurrentPmDetails(["No PMs found"]);
      }
      dispatch(rfqPostTradeSlice.setPBA(findClientPms[0]?.pba));
    }
    if (value !== "" && marketMaker !== "" && marketMaker !== undefined) {
      dispatch(rfqPostTradeSlice.setMMNeedsConfoAsync());
    }
  };

  const handleMarketMakerChange = (event, value) => {
    event.preventDefault();
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "marketMaker",
        value: value,
      }),
    );

    if (marketMaker !== value && marketMakerNeedsConfo) {
      if (marketMaker !== "") {
        dispatch(rfqPostTradeSlice.setMarketMaker(value));
        dispatch(rfqPostTradeSlice.setMMNeedsConfoAsync());
      }
    } else {
      dispatch(rfqPostTradeSlice.setMarketMaker(value));
      dispatch(rfqPostTradeSlice.setTrader(""));
    }
    if (currentMarketMaker === "OG-RBS") {
      let findOscarGrussMmTraders = oscarGrussMarketMakers.filter((key) => {
        if (key.ogName === value) {
          setMarketMakerId(key.id);
          return key.ogName;
        }
      });
      let traders = findOscarGrussMmTraders.map((key) => {
        return key.ogPm;
      });
      if (traders.length > 0 && traders[0] !== null) {
        setCurrentTraderDetails(traders);
      } else {
        setCurrentTraderDetails(["No Traders found"]);
      }
    }
    if (currentMarketMaker === "MAKOR-RBS") {
      if (sd === true) {
        let findMakorMakorMmTraders = makorMarketMakers.filter((key) => {
          if (key.MmName === value) {
            setMarketMakerId(key.id);
            return key.MmName;
          }
        });

        let traders = findMakorMakorMmTraders
          .map((key) => key.MmPm)
          .filter((pm) => pm !== null);

        let findClientPms = oscarGrussClients.filter((key) => {
          if (key.ogName === value) {
            setClientId(key.clientId);
            return key.ogName;
          }
        });

        let pms = findClientPms
          .map((key) => key.ogPm)
          .filter((pm) => pm !== null);

        if (traders.length === 0 && pms.length === 0) {
          setCurrentTraderDetails(["No PMs found"]);
        } else {
          setCurrentTraderDetails([...traders, ...pms]);
        }
        //  let concatArrays = [...makorMarketMakers,...oscarGrussMarketMakers]
      } else {
        let findMakorMakorMmTraders = makorMarketMakers.filter((key) => {
          if (key.MmName === value) {
            setMarketMakerId(key.id);
            return key.MmName;
          }
        });

        let traders = findMakorMakorMmTraders.map((key) => {
          return key.MmPm;
        });
        if (traders.length > 0 && traders[0] !== null) {
          setCurrentTraderDetails(traders);
        } else {
          setCurrentTraderDetails(["No Traders found"]);
        }
      }
    }
    if (currentMarketMaker === "MAKOR-HSBC") {
      let findClientPms = makorHSBCClient.filter((key) => {
        if (key.MmName === value) {
          setClientId(key.clientId);
          return key.MmName;
        }
      });
      let findMarketMakerPms = makorHSBCMarketMakers.filter((key) => {
        if (key.MmName === value) {
          setClientId(key.clientId);
          return key.MmName;
        }
      });
      let allPms = [...findClientPms, ...findMarketMakerPms];
      let pms = allPms.map((key) => {
        return key.MmPm;
      });
      if (pms.length > 0 && pms[0] !== null) {
        setCurrentTraderDetails(pms);
      } else {
        setCurrentTraderDetails(["No Traders found"]);
      }
    }

    dispatch(rfqPostTradeSlice.setMMNeedsConfoAsync(event.target.checked));
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "marketMakerNeedsConfo",
        value: true,
      }),
    );
  };

  const handleClientPb = (value) => {
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "currentPb",
        value: value,
      }),
    );
    dispatch(rfqPostTradeSlice.setCurrentPb(value));
  };

  const handleTrader = (value) => {
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "trader",
        value: value,
      }),
    );
    dispatch(rfqPostTradeSlice.setTrader(value));
  };

  const updateConfoInitial = (e) => {
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "confoInitial",
        value: e.target.value,
      }),
    );
    dispatch(rfqPostTradeSlice.setConfoInitial(e.target.value));
  };

  const updateDfMids = (e) => {
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "dfMids",
        value: e.target.value,
      }),
    );
    dispatch(rfqPostTradeSlice.setDfMids(e.target.value));
  };

  const updateTraderNote = (e) => {
    dispatch(
      rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
        rfq_id: rfq_object.rfq_id,
        key: "traderNote",
        value: e.target.value,
      }),
    );
    dispatch(rfqPostTradeSlice.setTraderNote(e.target.value));
  };

  const handleCheckBoxClicks = (event, type) => {
    if (type === "singleConfo") {
      dispatch(rfqPostTradeSlice.setSingleConfo(event.target.checked));
      dispatch(
        rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
          rfq_id: rfq_object.rfq_id,
          key: "singleConfo",
          value: event.target.checked,
        }),
      );
    }
    if (type === "SD") {
      if (
        (sd === false && currentClient[0] !== "") ||
        currentClient[0] !== undefined
      ) {
        handleClientChange("");
      }
      dispatch(rfqPostTradeSlice.setSD(event.target.checked));
      dispatch(
        rfqPostTradeSlice.setPostTradeSplitArrPreValuesThunk({
          rfq_id: rfq_object.rfq_id,
          key: "SD",
          value: event.target.checked,
        }),
      );
      if (event.target.checked === true) {
        let arrangeOscarGrussClients = oscarGrussClients.map((key) => {
          return key.ogName;
        });
        let OscarGrussClients = [...new Set(arrangeOscarGrussClients)];
        setCurrentClientsDetails((prev) => OscarGrussClients);

        let arrangeMakorMarketMakers = makorMarketMakers?.map((key) => {
          return key.MmName;
        });
        let MakorMarketMakers = [...new Set(arrangeMakorMarketMakers)];

        let concatArray = [...MakorMarketMakers, ...OscarGrussClients];
        setCurrentMarketMakerDetails((prev) => concatArray);

        let findClientPms = oscarGrussClients.filter((key) => {
          if (key.ogName === rfq[0].currentClient) {
            setClientId(key.clientId);
            return key.ogName;
          }
        });
        let pms = findClientPms.map((key) => {
          return key.ogPm;
        });

        if (pms.length > 0 && pms[0] !== null) {
          setCurrentPmDetails(pms);
        } else {
          setCurrentPmDetails(["No PMs found"]);
        }
      } else {
        let arrangeMakorClients = makorClients.map((key) => {
          return key.MmName;
        });
        let MakorClients = [...new Set(arrangeMakorClients)];
        setCurrentClientsDetails((prev) => MakorClients);
      }
    }
  };

  const handleCloseModal = () => {
    if (startSendEmailStatus === true) {
      return;
    } else {
      setOpenConfoDialog(!openConfoDialog);
      dispatch(emailActions.setEmailSavedData("clear"));
    }
  };

  //************************************************************* */
  useEffect(() => {
    const filteredRfq = rfq.filter(
      (r) => Number(r.rfq_id) === Number(rfqIdStorage),
    );
    if (filteredRfq.length > 0) {
      setRfqStatus(filteredRfq[0].status);
    }
  }, [rfqIdStorage, rfq]);
  useEffect(() => {
    if (thereIsConfo || booked) {
      return;
    }
  }, [currentMarketMaker]);

  useEffect(() => {
    if (thereIsConfo) {
      return;
    } else {
      dispatch(emailActions.setRfqEmail(""));
    }
  }, [currentClient[0], currentMarketMaker]);

  useEffect(() => {
    // dispatch(rfqPostTradeSlice.setRfqObject(rfq_object));
    if (thereIsConfo) {
      return;
    } else {
      dispatch(rfqPostTradeSlice.clearCurrentClients());
      dispatch(rfqPostTradeSlice.setCurrentPb(""));
      dispatch(rfqPostTradeSlice.setMarketMaker(""));
      dispatch(rfqPostTradeSlice.setTrader(""));
      dispatch(rfqPostTradeSlice.setMMNeedsConfo(false));
      dispatch(rfqPostTradeSlice.setCurrentMarketMaker(""));
      dispatch(rfqPostTradeSlice.setDfMids(""));
      dispatch(rfqPostTradeSlice.setTraderNote(""));
      dispatch(rfqPostTradeSlice.setConfoInitial(""));
      dispatch(rfqPostTradeSlice.setSD(false));
    }
    return () => {
      dispatch(rfqPostTradeSlice.setCurrentPb(""));
      dispatch(rfqPostTradeSlice.setMarketMaker(""));
      dispatch(rfqPostTradeSlice.setTrader(""));
      dispatch(rfqPostTradeSlice.setMMNeedsConfo(false));
      dispatch(rfqPostTradeSlice.setCurrentMarketMaker(""));
      dispatch(rfqPostTradeSlice.setDfMids(""));
      dispatch(rfqPostTradeSlice.setTraderNote(""));
      dispatch(rfqPostTradeSlice.setConfoInitial(""));
      dispatch(rfqPostTradeSlice.setSD(false));
    };
  }, []);

  const bookTrade = async () => {
    const token = sessionStorage.getItem("token");
    if (currentMarketMaker === "") {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Please select PB Market Maker",
          3000,
        ),
      );
    } else if (
      (currentClient.length === 0 || currentClient[0] === null) &&
      postTradeSplitArrValues.length === 0
    ) {
      dispatch(
        actionSnackBar.setSnackBar("error", "Please select Client", 3000),
      );
    } else if (marketMaker === "" || marketMaker === null) {
      dispatch(
        actionSnackBar.setSnackBar("error", "Please select Marker Maker", 3000),
      );
    } else if (traderNote === "") {
      dispatch(
        actionSnackBar.setSnackBar(
          "error",
          "Please fill trader note field",
          3000,
        ),
      );
    } else if (
      currentMarketMaker !== "" &&
      (currentClient.length !== 0 || currentClient[0] !== null) &&
      (marketMaker !== "" || marketMaker !== null) &&
      traderNote !== ""
    ) {
      let allocation_list;
      if (postTradeSplitArrPreValues.length > 0) {
        const rfq = postTradeSplitArrPreValues?.filter(
          (t) => Number(t.rfq_id) === Number(rfq_object.rfq_id),
        );
        if (rfq.length > 0) {
          if (splitManner === "percentage") {
            allocation_list = rfq[0].split_values.map((key) => {
              return {
                id: key.id,
                client_selection: key.postTradeClientSplitId,
                pm_selection: key.postTradePmSplitId,
                percentange_allocation: +key.postTradeSplitOption / 100,
              };
            });
          } else if (splitManner === "notional") {
            if (DEAL_TYPE !== "SWAP") {
              allocation_list = postTradeSplitArrValues.map((split) => {
                let obj = {
                  id: split.id,
                  client_selection: split.postTradeClientSplitId,
                  pm_selection: split.postTradePmSplitId,
                  notional_allocation: [{ 0: "OPTION" }, { 0: "HEDGE" }],
                };
                for (const [key, value] of Object.entries(split)) {
                  if (key.includes("Leg")) {
                    let legNum = key.match(/\d+/g);
                    obj.notional_allocation[0] = {
                      ...obj.notional_allocation[0],
                      [legNum]: value,
                    };
                  }
                  if (key.includes("Delta")) {
                    let deltaNum = key.match(/\d+/g);
                    obj.notional_allocation[1] = {
                      ...obj.notional_allocation[1],
                      [deltaNum]: value,
                    };
                  }
                }
                return obj;
              });
            } else {
              allocation_list = postTradeSplitArrValues.map((split) => {
                let obj = {
                  id: split.id,
                  client_selection: split.postTradeClientSplitId,
                  pm_selection: split.postTradePmSplitId,
                  notional_allocation: [{ 0: "SWAP", 1: 0, 2: 0 }],
                };
                for (const [key, value] of Object.entries(split)) {
                  if (key.includes("near")) {
                    obj.notional_allocation["1"] = value;
                  }
                  if (key.includes("far")) {
                    obj.notional_allocation["2"] = value;
                  }
                }
                return obj;
              });
            }
          }
        }
      } else {
        if (splitManner === "percentage") {
          allocation_list = postTradeSplitArrValues.map((key) => {
            return {
              client_selection: key.postTradeClientSplitId,
              pm_selection: key.postTradePmSplitId,
              percentange_allocation: +key.postTradeSplitOption / 100,
            };
          });
        } else if (splitManner === "notional") {
          setNotionalSplitCopy(postTradeSplitArrValues);
          if (DEAL_TYPE !== "SWAP") {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "OPTION" }, { 0: "HEDGE" }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("Leg")) {
                  let legNum = key.match(/\d+/g);
                  obj.notional_allocation[0] = {
                    ...obj.notional_allocation[0],
                    [legNum]: value,
                  };
                }
                if (key.includes("Delta")) {
                  let deltaNum = key.match(/\d+/g);
                  obj.notional_allocation[1] = {
                    ...obj.notional_allocation[1],
                    [deltaNum]: value,
                  };
                }
              }
              return obj;
            });
          } else {
            allocation_list = postTradeSplitArrValues.map((split) => {
              let obj = {
                id: split.id,
                client_selection: split.postTradeClientSplitId,
                pm_selection: split.postTradePmSplitId,
                notional_allocation: [{ 0: "SWAP", 1: 0, 2: 0 }],
              };
              for (const [key, value] of Object.entries(split)) {
                if (key.includes("near")) {
                  obj.notional_allocation["1"] = value;
                }
                if (key.includes("far")) {
                  obj.notional_allocation["2"] = value;
                }
              }
              return obj;
            });
          }
        }
      }

      let confo_details = {};
      if (currentMarketMaker === "MAKOR-HSBC") {
        let curentClientMarketMaker = makorHSBCMarketMakers.some(
          (mm) => mm.MmName === currentClient[0],
        );
        let marketMakerIsClient = makorHSBCClient.some(
          (client) => client.MmName === marketMaker,
        );
        let newAllocationList;
        if (allocation_list?.length > 0) {
          newAllocationList = allocation_list.map((alo) => {
            let curentClientMarketMaker = makorHSBCMarketMakers.some(
              (mm) => mm.MmName === alo.client_selection,
            );
            let marketMakerIsClient = makorHSBCClient.some(
              (client) => client.MmName === alo.client_selection,
            );
            alo = {
              ...alo,
              is_marketmaker_client: marketMakerIsClient,
              is_client_marketmaker: curentClientMarketMaker,
            };
            return alo;
          });
        }

        confo_details = {
          client_selection: currentClient[0],
          market_maker_selection: marketMaker,
          pm_selection: currentPb !== "" ? currentPb : "",
          trader_selection: trader !== "" ? trader : "",
          trader_note: traderNote,
          df_mids: dfMids,
          market_maker_needs_confo: true,
          confo_initial: confoInitial,
          pb: currentMarketMaker,
          singleConfo: allocation_list?.length > 0 ? singleConfo : null,
          is_client_marketmaker: curentClientMarketMaker,
          is_marketmaker_client: marketMakerIsClient,
          pba: pba,
        };
      } else {
        confo_details = {
          client_selection: currentClient[0],
          market_maker_selection: marketMaker,
          pm_selection: currentPb !== "" ? currentPb : "",
          trader_selection: trader !== "" ? trader : "",
          trader_note: traderNote,
          df_mids: dfMids,
          market_maker_needs_confo: true,
          confo_initial: confoInitial,
          pb: currentMarketMaker,
          singleConfo: allocation_list?.length > 0 ? singleConfo : null,
          pba: pba,
        };
      }
      if (allocation_list?.length > 0) {
        confo_details = {
          ...confo_details,
          split_allocation: allocation_list,
        };
      } else {
        confo_details = { ...confo_details, split_allocation: null };
      }

      if (currentMarketMaker === "MAKOR-RBS") {
        if (sd === true) {
          confo_details = { ...confo_details, SD: sd };
        }
      }

      let res = rfq.filter(
        (r) => Number(r.rfq_id) === Number(rfq_object.rfq_id),
      );

      let newStatus =
        res[0]?.status !== undefined ? res[0]?.status : res.status;
      newStatus =
        newStatus === "Live"
          ? "Booked"
          : newStatus === "Confirmed"
          ? "Confirmed & Booked"
          : "Booked";

      rfq_object = {
        ...rfq_object,
        status: newStatus,
        rfq_id: sessionStorage.getItem("rfqId"),
      };

      try {
        let response = await axios.post(
          `${process.env.REACT_APP_PYTON}` + `book_trade_preclearance`,
          {
            confo_details: confo_details,
            opts_data: rfq_object.rfq_table_data,
          },
        );

        rfq_object = {
          ...rfq_object,
          confo_details: confo_details,
          allocation_details: response.data,
        };

        let data = [
          response,
          rfq_object,
          confo_details,
          rfq_object.rfq_table_data,
        ];
        setBookTradeResponse(data);
        openBookDialogAction();
      } catch (error) {}
    }
  };

  //***************** PRE LOAD RFQ STATS  ******************************************* */
  useEffect(() => {
    if (postTradeSplitArrPreValues?.length !== 0) {
      const rfq = postTradeSplitArrPreValues?.filter(
        (t) => Number(t.rfq_id) === Number(sessionStorage.getItem("rfqId")),
      );
      if (rfq.length !== 0) {
        if (rfq[0].split_values.length > 0) {
          setSplitted(true);
          dispatch(rfqPostTradeSlice.setIsSplitted(true));
          for (const split of rfq[0].split_values) {
            const clientObj = {
              value: split.postTradeClientSplitId,
              key: "postTradeClientSplitId",
              id: split.id,
            };
            const pmObj = {
              value: split.postTradePmSplitId
                ? split.postTradePmSplitId
                : "no options",
              key: "postTradePmSplitId",
              id: split.id,
            };
            const optionObj = {
              value: +split.postTradeSplitOption,
              key: "postTradeSplitOption",
              id: split.id,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
            dispatch(rfqPostTradeSlice.setClientsWhenSplited());
          }
        } else {
          const obj = {
            value: 2,
            key: "postTradeSplitNumber",
          };
          dispatch(rfqPostTradeSlice.setPostTradeSplitNumber(obj));
          dispatch(rfqPostTradeSlice.resetPostTradeStats());
        }
        if (rfq[0].currentMarketMaker && rfq[0].currentMarketMaker !== "") {
          dispatch(
            rfqPostTradeSlice.setCurrentMarketMaker(rfq[0].currentMarketMaker),
          );

          if (rfq[0].currentMarketMaker === "OG-RBS") {
            let arrangeOscarGrussClients = oscarGrussClients.map((key) => {
              return key.ogName;
            });
            let OscarGrussClients = [...new Set(arrangeOscarGrussClients)];
            setCurrentClientsDetails((prev) => OscarGrussClients);

            let arrangeOscarGrussMarketMakers = oscarGrussMarketMakers.map(
              (key) => {
                return key.ogName;
              },
            );
            let OscarGrussMarketMakers = [
              ...new Set(arrangeOscarGrussMarketMakers),
            ];
            setCurrentMarketMakerDetails((prev) => OscarGrussMarketMakers);
          }
          if (rfq[0].currentMarketMaker === "MAKOR-RBS") {
            let arrangeMakorClients = makorClients.map((key) => {
              return key.MmName;
            });
            let MakorClients = [...new Set(arrangeMakorClients)];
            setCurrentClientsDetails((prev) => MakorClients);

            let arrangeMakorMarketMakers = makorMarketMakers.map((key) => {
              return key.MmName;
            });
            let MakorMarketMakers = [...new Set(arrangeMakorMarketMakers)];
            setCurrentMarketMakerDetails((prev) => MakorMarketMakers);
          }
          if (rfq[0].currentMarketMaker === "MAKOR-HSBC") {
            let arrangeMakorHSBCClients = allMakorHSBCClientAndMarketMakers.map(
              (key) => {
                return key.MmName;
              },
            );
            let MakorHSBCClients = [...new Set(arrangeMakorHSBCClients)];
            setCurrentClientsDetails((prev) => MakorHSBCClients);

            let arrangeMakorHSBCMarketMakers =
              allMakorHSBCClientAndMarketMakers.map((key) => {
                return key.MmName;
              });
            let MakorHSBCMarketMakers = [
              ...new Set(arrangeMakorHSBCMarketMakers),
            ];
            setCurrentMarketMakerDetails((prev) => MakorHSBCMarketMakers);
          }
          if (rfq[0].currentClient && rfq[0].currentClient !== "") {
            if (rfq[0].split_values.length === 0) {
              dispatch(
                rfqPostTradeSlice.setCurrentClient(rfq[0].currentClient),
              );
            }
            if (rfq[0].currentPb && rfq[0].currentPb !== "") {
              dispatch(rfqPostTradeSlice.setCurrentPb(rfq[0].currentPb));
            }
            if (rfq[0].currentMarketMaker === "OG-RBS") {
              let findClientPms = oscarGrussClients.filter((key) => {
                if (key.ogName === rfq[0].currentClient) {
                  setClientId(key.clientId);
                  return key.ogName;
                }
              });
              let pms = findClientPms.map((key) => {
                return key.ogPm;
              });

              if (pms.length > 0 && pms[0] !== null) {
                setCurrentPmDetails(pms);
              } else {
                setCurrentPmDetails(["No PMs found"]);
              }
            }
            if (rfq[0].currentMarketMaker === "MAKOR-RBS") {
              let findClientPms = makorClients.filter((key) => {
                if (key.MmName === rfq[0].currentClient) {
                  setClientId(key.clientId);
                  return key.MmName;
                }
              });
              let pms = findClientPms.map((key) => {
                return key.MmPm;
              });
              if (pms.length > 0 && pms[0] !== null) {
                setCurrentPmDetails(pms);
              } else {
                setCurrentPmDetails(["No PMs found"]);
              }
            }
          }
        }

        if (rfq[0].marketMaker && rfq[0].marketMaker !== "") {
          dispatch(rfqPostTradeSlice.setMarketMaker(rfq[0].marketMaker));

          if (rfq[0].trader && rfq[0].trader !== "") {
            dispatch(rfqPostTradeSlice.setTrader(rfq[0].trader));
          }
          if (rfq[0].currentMarketMaker === "OG-RBS") {
            let findOscarGrussMmTraders = oscarGrussMarketMakers.filter(
              (key) => {
                if (key.ogName === rfq[0].marketMaker) {
                  setMarketMakerId(key.id);
                  return key.ogName;
                }
              },
            );
            let traders = findOscarGrussMmTraders.map((key) => {
              return key.ogPm;
            });
            if (traders.length > 0 && traders[0] !== null) {
              setCurrentTraderDetails(traders);
            } else {
              setCurrentTraderDetails(["No Traders found"]);
            }
          }
          if (rfq[0].currentMarketMaker === "MAKOR-RBS") {
            let findOscarMakorMmTraders = makorMarketMakers.filter((key) => {
              if (key.MmName === rfq[0].marketMaker) {
                setMarketMakerId(key.id);
                return key.MmName;
              }
            });

            let traders = findOscarMakorMmTraders.map((key) => {
              return key.MmPm;
            });
            if (traders.length > 0 && traders[0] !== null) {
              setCurrentTraderDetails(traders);
            } else {
              setCurrentTraderDetails(["No Traders found"]);
            }
          }
        }

        if (rfq[0].dfMids && rfq[0].dfMids !== "") {
          dispatch(rfqPostTradeSlice.setDfMids(rfq[0].dfMids));
        }
        if (rfq[0].confoInitial && rfq[0].confoInitial !== "") {
          dispatch(rfqPostTradeSlice.setConfoInitial(rfq[0].confoInitial));
        }
        if (
          rfq[0].marketMakerNeedsConfo &&
          rfq[0].marketMakerNeedsConfo !== ""
        ) {
          dispatch(
            rfqPostTradeSlice.setMMNeedsConfo(rfq[0].marketMakerNeedsConfo),
          );
          dispatch(
            rfqPostTradeSlice.addMMToClients(`${rfq[0].marketMaker} (MM)`),
          );
        }
        if (rfq[0].traderNote && rfq[0].traderNote !== "") {
          dispatch(rfqPostTradeSlice.setTraderNote(rfq[0].traderNote));
        }
        if (rfq[0].singleConfo && rfq[0].singleConfo !== null) {
          dispatch(rfqPostTradeSlice.setSingleConfo(true));
        }
        // if (rfq[0]?.PBA && rfq[0]?.PBA !== null) {
        //   dispatch(rfqPostTradeSlice.setPBA(rfq[0]?.PBA));
        // }
        if (rfq[0]?.SD && rfq[0]?.SD !== null) {
          dispatch(rfqPostTradeSlice.setSD(rfq[0]?.SD));
        }
      } else {
        dispatch(rfqPostTradeSlice.resetPostTradeStats());
      }
    }
  }, [rfqObject]);

  //************************************************** */
  useEffect(() => {
    if (booked) {
      dispatch(
        rfqPostTradeSlice.setCurrentMarketMaker(
          bookedValues.booked_trade_obj_values?.pb,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setCurrentClient(
          bookedValues.booked_trade_obj_values.client_selection,
        ),
      );
      let fullDetailClient;

      let alphabetRegex = new RegExp(/(?!.*\()(?=.*\))[A-Z]/g);
      let currentClientSelection =
        bookedValues.booked_trade_obj_values.client_selection;
      if (alphabetRegex.test(currentClientSelection)) {
        currentClientSelection = currentClientSelection
          ?.replace(alphabetRegex, "")
          .replace(" ()", "");
      }
      switch (bookedValues.booked_trade_obj_values?.pb) {
        case "MAKOR-RBS":
          if (bookedValues.booked_trade_obj_values.SD !== undefined) {
            fullDetailClient = oscarGrussClients.filter(
              (client) => client.ogName === currentClientSelection,
            );
          } else {
            fullDetailClient = makorClients.filter(
              (client) => client.MmName === currentClientSelection,
            );
          }
          break;
        case "OG-RBS":
          fullDetailClient = oscarGrussClients.filter(
            (client) => client.ogName === currentClientSelection,
          );
          break;
        case "MAKOR-HSBC":
          fullDetailClient = allMakorHSBCClientAndMarketMakers.filter(
            (client) => client.MmName === currentClientSelection,
          );
          break;
        default:
          break;
      }
      if (
        fullDetailClient[0]?.pba !== null &&
        fullDetailClient[0]?.pba !== undefined
      ) {
        dispatch(rfqPostTradeSlice.setPBA(fullDetailClient[0].pba));
      }
      dispatch(
        rfqPostTradeSlice.setMarketMaker(
          bookedValues.booked_trade_obj_values.market_maker_selection,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setCurrentPb(
          bookedValues.booked_trade_obj_values.pm_selection,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setTrader(
          bookedValues.booked_trade_obj_values.trader_selection,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setDfMids(
          bookedValues.booked_trade_obj_values.df_mids,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setConfoInitial(
          bookedValues.booked_trade_obj_values.confo_initial,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setMMNeedsConfo(
          bookedValues.booked_trade_obj_values.market_maker_needs_confo,
        ),
      );

      dispatch(
        rfqPostTradeSlice.addMMToClients(
          `${bookedValues.booked_trade_obj_values.market_maker_selection} (MM)`,
        ),
      );
      dispatch(
        rfqPostTradeSlice.setTraderNote(
          bookedValues.booked_trade_obj_values.trader_note,
        ),
      );

      if (bookedValues.booked_trade_obj_values?.pb === "MAKOR-RBS") {
        dispatch(
          rfqPostTradeSlice.setSD(bookedValues.booked_trade_obj_values.SD),
        );
      }
      //setCurrentPb,setTrader,setDfMids,setConfoInitial,setMMNeedsConfo,setTraderNote
      if (bookedValues.booked_trade_obj_values?.split_values.length > 0) {
        dispatch(
          rfqPostTradeSlice.setPostTradeSplitNumber({
            key: "postTradeSplitNumber",
            value: bookedValues?.booked_trade_obj_values.split_values.length,
          }),
        );
        if (
          bookedValues.booked_trade_obj_values?.split_values[0].hasOwnProperty(
            "Leg1NotionalSplitOption",
          )
        ) {
          dispatch(rfqPostTradeSlice.setSplitManner("notional"));
          for (const split of bookedValues?.booked_trade_obj_values
            ?.split_values) {
            for (const [key, value] of Object.entries(split)) {
              if (key.includes("Delta") || key.includes("Leg")) {
                let optionObj = {
                  value: split[key],
                  key: key,
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj),
                );
              }
              const clientObj = {
                value: split.client_selection,
                key: "postTradeClientSplitId",
                id: split.id,
              };
              const pmObj = {
                value: split.pm_selection,
                key: "postTradePmSplitId",
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj),
              );
              dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
              dispatch(rfqPostTradeSlice.setClientsWhenSplited());
              dispatch(
                rfqPostTradeSlice.setSingleConfo(
                  bookedValues?.booked_trade_obj_values?.singleConfo,
                ),
              );
            }
          }
        } else if (
          bookedValues.booked_trade_obj_values?.split_values[0].hasOwnProperty(
            "nearNotional",
          )
        ) {
          dispatch(rfqPostTradeSlice.setSplitManner("notional"));
          for (const split of bookedValues?.booked_trade_obj_values
            ?.split_values) {
            for (const [key, value] of Object.entries(split)) {
              if (key.includes("near") || key.includes("far")) {
                let optionObj = {
                  value: split[key],
                  key: key,
                  id: split.id,
                };
                dispatch(
                  rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj),
                );
              }
              const clientObj = {
                value: split.client_selection,
                key: "postTradeClientSplitId",
                id: split.id,
              };
              const pmObj = {
                value: split.pm_selection,
                key: "postTradePmSplitId",
                id: split.id,
              };
              dispatch(
                rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj),
              );
              dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
              dispatch(rfqPostTradeSlice.setClientsWhenSplited());
              dispatch(
                rfqPostTradeSlice.setSingleConfo(
                  bookedValues?.booked_trade_obj_values?.singleConfo,
                ),
              );
            }
          }
        } else {
          // dispatch(rfqPostTradeSlice.setSplitManner("percentage"));
          for (const split of bookedValues?.booked_trade_obj_values
            .split_values) {
            const clientObj = {
              value: split.client_selection,
              key: "postTradeClientSplitId",
              id: split.id,
            };
            const pmObj = {
              value: split.pm_selection,
              key: "postTradePmSplitId",
              id: split.id,
            };
            const optionObj = {
              value: split.percentange_allocation * 100,
              key: "postTradeSplitOption",
              id: split.id,
            };
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(optionObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(pmObj));
            dispatch(rfqPostTradeSlice.setPostTradesSplitArrValues(clientObj));
          }
          dispatch(rfqPostTradeSlice.setClientsWhenSplited());
          dispatch(
            rfqPostTradeSlice.setSingleConfo(
              bookedValues?.booked_trade_obj_values?.singleConfo,
            ),
          );
        }
        setSplitted(true);
        dispatch(rfqPostTradeSlice.setIsSplitted(true));
      }
    }
  }, [booked]);

  return (
    <Grid
      item
      container
      xs={12}
      // style={{ height: "20vh" }}
      className={classes.rfqModal}
    >
      <Grid item container>
        <Grid item container className={classes.rfqModalHeader}>
          <Typography className={classes.header}>{header}</Typography>
        </Grid>
        <Grid
          item
          container
          style={{
            color: "white",
            background: "#222222",
            display: "flex",
            flex: "5",
          }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: "10px 20px 10px 22px" }}
          >
            <Grid item xs={9}>
              <StyledTextField
                style={{
                  width: "100%",
                }}
                defaultValue={pbValue}
                disabled={rfqStatus !== "Live"}
                className={classes.arrowIcon}
                id="outlined-select-currency"
                select
                label={
                  booked && bookedValues.booked_trade_obj_values !== null
                    ? bookedValues.booked_trade_obj_values.pb
                    : currentMarketMaker === "" || currentMarketMaker === null
                    ? "PB"
                    : currentMarketMaker
                }
                InputLabelProps={{ shrink: false }}
                onChange={(e) => handleMmChange(e, e.target.value)}
                variant="outlined"
                SelectProps={{
                  multiple: false,
                  value: "",
                }}
              >
                {marketMakers.map((option, key) => (
                  <MenuItem className={classes.pb} key={key} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                className={
                  booked && bookedValues.booked_trade_obj_values !== null
                    ? bookedValues.booked_trade_obj_values
                        .market_maker_needs_confo
                    : classes.checkBoxFormControler
                }
                labelPlacement="bottom"
                control={
                  <Checkbox
                    disabled={
                      thereIsConfo === true ||
                      rfqStatus !== "Live" ||
                      currentMarketMaker !== "MAKOR-RBS"
                    }
                    checked={sd}
                    labelPlacement="bottom"
                    className={classes.checkBox}
                    onClick={(event) => handleCheckBoxClicks(event, "SD")}
                  />
                }
                label="SD"
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={clsx(
                  !isSplited
                    ? classes.postTradeSplitBtn
                    : classes.selectedPostTradeSplitBtn,
                )}
                onClick={openSplitDialogAction}
                // disabled={rfqStatus !== "Live"}
              >
                <Split />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{
              paddingTop: 16,
              // backgroundColor: "lightseagreen",
              padding: "10px 10px",
            }}
          >
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item container xs={2} justifyContent="center">
                <Client />
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  disabled={rfqStatus !== "Live"}
                  className={classes.autoComp}
                  onChange={(event, value) => handleClientChange(value)}
                  value={
                    booked && bookedValues.booked_trade_obj_values !== null
                      ? bookedValues.booked_trade_obj_values.client_selection
                      : currentClient[0] === "" ||
                        currentClient[0] === undefined
                      ? "Client"
                      : currentClient[0]
                  }
                  options={currentClientsDetails}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="outlined"
                      style={{ width: "100%" }}
                      className={classes.arrowIcon}
                      SelectProps={{
                        multiple: false,
                        value: "",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item container xs={2} justifyContent="center">
                <Pm />
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  disabled={
                    rfqStatus !== "Live" || postTradeSplitArrValues.length > 1
                  }
                  className={classes.autoComp}
                  onChange={(event, value) => handleClientPb(value)}
                  value={
                    booked && bookedValues.booked_trade_obj_values !== null
                      ? bookedValues.booked_trade_obj_values.pm_selection
                      : currentPb === "" || currentPb === null
                      ? "Pm"
                      : currentPb
                  }
                  options={currentPmDetails}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      variant="outlined"
                      style={{ width: "100%" }}
                      className={classes.arrowIcon}
                      SelectProps={{
                        multiple: false,
                        value: "",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-evenly"
              style={{ paddingTop: 16 }}
            >
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item container xs={2} justifyContent="center">
                  <Mm />
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    disabled={rfqStatus !== "Live"}
                    className={classes.autoComp}
                    onChange={(event, value) =>
                      handleMarketMakerChange(event, value)
                    }
                    value={
                      booked && bookedValues.booked_trade_obj_values !== null
                        ? bookedValues.booked_trade_obj_values
                            .market_maker_selection
                        : marketMaker === "" || marketMaker === null
                        ? "Market Maker"
                        : marketMaker
                    }
                    options={currentMarketMakerDetails}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="outlined"
                        style={{ width: "100%" }}
                        className={classes.arrowIcon}
                        SelectProps={{
                          multiple: false,
                          value: "",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item container xs={2} justifyContent="center">
                  <Trader />
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    disabled={rfqStatus !== "Live"}
                    className={classes.autoComp}
                    onChange={(event, value) => handleTrader(value)}
                    value={
                      booked && bookedValues.booked_trade_obj_values !== null
                        ? bookedValues.booked_trade_obj_values.trader_selection
                        : trader === "" || trader === null
                        ? "Trader"
                        : trader
                    }
                    options={currentTraderDetails}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="outlined"
                        style={{ width: "100%" }}
                        className={classes.arrowIcon}
                        SelectProps={{
                          multiple: false,
                          value: "",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                style={{ paddingTop: 16 }}
                justifyContent="space-between"
              >
                <Grid item container xs={1} justifyContent="center">
                  <Note />
                </Grid>
                <Grid item xs={11}>
                  <StyledTextField
                    disabled={rfqStatus !== "Live"}
                    placeholder={
                      booked && bookedValues.booked_trade_obj_values !== null
                        ? bookedValues.booked_trade_obj_values.trader_note
                        : traderNote === "" || traderNote === null
                        ? "Trader Note"
                        : traderNote
                    }
                    onChange={(e) => updateTraderNote(e)}
                    style={{
                      width: "100%",
                    }}
                    variant="outlined"
                  ></StyledTextField>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="space-evenly"
                style={{ paddingTop: 16 }}
              >
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item container xs={2} justifyContent="center">
                    <DfMids />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledTextField
                      disabled={rfqStatus !== "Live"}
                      style={{
                        width: "100%",
                        color: "white",
                      }}
                      className={classes.arrowIcon}
                      placeholder={
                        booked && bookedValues.booked_trade_obj_values !== null
                          ? bookedValues.booked_trade_obj_values.df_mids
                          : dfMids === "" || dfMids === null
                          ? "DF MIDS"
                          : dfMids
                      }
                      onChange={(e) => updateDfMids(e)}
                      variant="outlined"
                    ></StyledTextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item container xs={2} justifyContent="center">
                    <Info />
                  </Grid>
                  <Grid item xs={10}>
                    <StyledTextField
                      disabled={rfqStatus !== "Live"}
                      style={{
                        width: "100%",
                      }}
                      className={classes.arrowIcon}
                      placeholder={
                        booked && bookedValues.booked_trade_obj_values !== null
                          ? bookedValues.booked_trade_obj_values.confo_initial
                          : confoInitial === "" || confoInitial === null
                          ? "Confo initial"
                          : confoInitial
                      }
                      variant="outlined"
                      onChange={(e) => updateConfoInitial(e)}
                    ></StyledTextField>
                  </Grid>
                </Grid>
              </Grid>
              {postTradeSplitArrValues.length > 0 && (
                <Grid item>
                  <FormControlLabel
                    className={
                      booked && bookedValues.booked_trade_obj_values !== null
                        ? bookedValues.booked_trade_obj_values
                            .market_maker_needs_confo
                        : classes.checkBoxFormControler
                    }
                    control={
                      <Checkbox
                        disabled={thereIsConfo === true || rfqStatus !== "Live"}
                        checked={singleConfo}
                        className={classes.checkBox}
                        onClick={(event) =>
                          handleCheckBoxClicks(event, "singleConfo")
                        }
                      />
                    }
                    label="Single Confo"
                  />
                </Grid>
              )}
              <Grid
                item
                container
                style={{
                  paddingTop:
                    postTradeSplitArrValues.length > 0 ? "0px" : "36px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid
                  item
                  xs={
                    currentClient.length === 2 &&
                    clientVerifier(currentClient) &&
                    DEAL_TYPE === "SWAP"
                      ? 4
                      : 6
                  }
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    style={{
                      width: "calc(100% - 10px)",
                      height: 40,
                      border: "1px solid #FF681C",
                      color: "#FF681C",
                      textTransform: "none",
                      fontSize: "1.25rem",
                    }}
                    onClick={() => openConfoDialogAction("FX")}
                    startIcon={<Email />}
                  >
                    Create Confo
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={
                    currentClient.length === 2 &&
                    clientVerifier(currentClient) &&
                    DEAL_TYPE === "SWAP"
                      ? 4
                      : 6
                  }
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={bookTrade}
                    style={{
                      width: "calc(100% - 10px)",
                      height: 40,
                      backgroundColor: "#2692FF",
                      color: "#ffffff",
                      textTransform: "none",
                      fontSize: "1.25rem",
                    }}
                    startIcon={<Launch />}
                  >
                    {rfqStatus === "Booked" ||
                    rfqStatus === "Confirmed & Booked"
                      ? "Booked"
                      : "Book Trade"}
                  </Button>
                </Grid>

                {currentClient.length === 2 &&
                clientVerifier(currentClient) &&
                DEAL_TYPE === "SWAP" ? (
                  <Grid
                    item
                    xs={4}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={() => setOpenEConfoDialog(true)}
                      style={{
                        width: "calc(100% - 10px)",
                        height: 40,
                        border: "1px solid #1cffca",
                        color: "#1cffca",
                        textTransform: "none",
                        fontSize: "1.25rem",
                      }}
                      startIcon={
                        <CheckedCircleIcon style={{ fill: "#1cffca" }} />
                      }
                    >
                      E - Confo
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EmailModal
        open={openConfoDialog}
        type={confoModalType}
        handleClose={handleCloseModal}
        data={confirmedResponse}
      />
      <RfqSplitModal
        open={openSplitDialog}
        type={"split"}
        handleClose={handleSplitDialogClose}
        setSplitted={setSplitted}
        rfq_object={rfq_object}
        confirmed={confirmed}
        isBooked={booked}
      />
      <RfqBookModal
        open={openBookDialog}
        data={bookTradeResponse}
        handleClose={handleBookDialogClose}
      />
      <RfqBookModal
        open={openBookDialog}
        data={bookTradeResponse}
        handleClose={handleBookDialogClose}
      />
      <RfqEConfoModal
        open={openEConfoDialog}
        data={bookTradeResponse}
        handleClose={handleEConfoDialogClose}
        rfqObject={rfqObject}
      />
    </Grid>
  );
};
export default RfqBookingMode;
